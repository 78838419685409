#attendance_tab_content::-webkit-scrollbar,
#attendance_row_messages_chat::-webkit-scrollbar,
#attendance_tab_content::-webkit-scrollbar {
    width: 12px;
}

#attendance_tab_content::-webkit-scrollbar-track,
#attendance_row_messages_chat::-webkit-scrollbar-track,
#attendance_tab_content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 2px;
}

#attendance_tab_content::-webkit-scrollbar-thumb,
#attendance_row_messages_chat::-webkit-scrollbar-thumb,
#attendance_tab_content::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgb(36, 49, 64);
    box-shadow: inset 0 0 3px rgba(160, 180, 200, 0.85);
    
}